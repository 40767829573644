var React = require("react");
var createReactClass = require('create-react-class');

var FooterDateTime = createReactClass({
  render: function() {
    return (
      <div className="footer-date-time-container">
        <div className="footer-date-time">
          <div className="footer-date-time-cv">
            Powered by CiscoVision
          </div>
          <div className="footer-date-time-clock">
            {this.props.clock.format('h:mm a').replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.')}
            {/* {this.props.clock.format('dddd, MMMM D • h:mm a').replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.')} */}
          </div>
        </div>
      </div>
    );
  }
});

module.exports = FooterDateTime
