import React, { Component, useState } from 'react'
import Cookies from "universal-cookie";


const AdminAssignment = (props) => {
  const [ registrations, setRegistrations ] = useState(props.registrations.sort((a,b)=> b.id - a.id));

  const renderRegistrations = (registrations) => {
    const rows = registrations.map(registration => {
      return (
        <tr className="admin-assignment-row">
          <td>{registration.code}</td>
          <td>
            <input
              type="text"
              value={registration.room_name}
              onChange={(e) => {
                registration.room_name = e.target.value;
                setRegistrations(registrations)
              }}
              placeholder="Room Name (Must Match Exactly)"
            />
          </td>
          <td>
            <input
              type="text"
              value={registration.override_room_name}
              placeholder="Override Room Name"
              onChange={(e) => {
                registration.override_room_name = e.target.value;
                setRegistrations(registrations)
              }}/>
          </td>
          <td>
            <button
              type="button"
              onClick={()=>updateRegistration(registration)}
            > Save </button>
          </td>
        </tr>
      )
    })
    return rows;
  }

  const updateRegistration = (registration) => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    fetch(`/admin/registrations/${registration.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        registration: registration
      })
    })
    .then(res => {
      return res.json();
    })
    .then(json => {
      if (!json.error) {
        alert("Room assignment updated");
      } else {
        alert("Something went wrong with your request")
      }
    })
  }

  return (
    <div>

      <div className="row">
        <div className="col-xs-12">
          <div className="admin-assignment-title">Room Assignment</div>
        </div>
      </div>

      <table class="table">
        {renderRegistrations(registrations)}
      </table>

    </div>
  )
};

export default AdminAssignment