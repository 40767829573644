import React from "react";
import dateFormat from "dateformat";
import ReactTooltip from "react-tooltip";
import AdminSessionForm from "./admin_session_form";

class AdminSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formOpen: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

  getTime() {
    const { session } = this.props;
    if (session.start_time) {
      const timeDate = new Date(session.start_time);
      return dateFormat(timeDate, "hh:MM TT",true);
    }
    return "Unassigned";
  }

  getDate() {
    const { session } = this.props;
    if (session.date) {
      const timeDate = new Date(session.date);
      return dateFormat(timeDate, "dddd, mmmm dS",true);
    }
    return "Unassigned";
  }

  toggleOpen() {
    this.setState({
      formOpen: !this.state.formOpen
    });
  }


  renderErrorTooltip() {
    const { session } = this.props;
    const missingFields = this.getMissingFields();
    if (missingFields.length > 0) {
      return (
        <ReactTooltip id={`tooltip-session-${session.id}`} type='error'>
          <span>
            Missing Fields:&nbsp;
            {missingFields.join(", ")}
            <br />
            This row will not be shown until errors are fixed.
          </span>
        </ReactTooltip>
      );
    }
  }

  getMissingFields() {
    const { session } = this.props;
    let requiredFields = ['title', 'date', 'start_time'];
    requiredFields = requiredFields.filter((field) => session[field] === null || session[field] === "");
    return requiredFields;
  }

  closeForm() {
    this.setState({
      formOpen: false
    });
  }

  renderError() {
    const { session } = this.props;
    const missingFields = this.getMissingFields();
    if (missingFields.length <= 0) {
      return "";
    }
    return (
      <div className="session-error">
        !
      </div>
    );
  }

  render() {
    const { session, updateSession, deleteSession } = this.props;
    return (
      <div className="admin-session">
        {this.renderErrorTooltip()}
        <table onClick={this.toggleOpen} data-tip data-for={`tooltip-session-${session.id}`}>
          <tbody>
            <tr>
              <td>
                {this.getTime()}
              </td>
              <td>
                {this.getDate()}
              </td>
              <td>
                {session.title}
              </td>
              <td>
                {session.room}
              </td>
              <td className="session-icon-area">
                {this.renderError()}
              </td>
            </tr>
          </tbody>
        </table>
        <AdminSessionForm closeForm={this.closeForm} onDelete={deleteSession} onCancel={this.closeForm} updateSession={updateSession} session={session} open={this.state.formOpen} />
      </div>
    );
  }
}

export default AdminSession;
