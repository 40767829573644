import React from "react";
import Cookies from "universal-cookie";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import { Formik, Field, Form } from "formik";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment-timezone';


const format = 'h:mm a';

const now = moment().hour(0).minute(0);

class AdminSessionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  isOpen() {
    const { open } = this.props;
    return open !== false;
  }

  setDate(value,formikProps) {
    console.log({value});
    const formattedValue = dateFormat(value, "yyyy-mm-dd");
    console.log(formattedValue);
    formikProps.setFieldValue("session[date]", formattedValue);
  }

  setTime(value, name, formikProps) {
    const { setFieldValue } = formikProps;
    if(value){
      setFieldValue(name, value.format());
    }else{
      setFieldValue(name, null);
    }
  }

  renderForm(formikProps) {
    const { session } = this.props;
    console.log(formikProps);
    const { values } = formikProps;

    return (
      <Form autoComplete="off" id={`${session.id || "new"}-session-form`} className={`admin-session-form ${this.isOpen() ? "admin-session-form-open" : "admin-session-form-closed"}`}>
        <label>Session Name: </label>
        <Field type="text" name="session[title]" className="form-control"/>
        <label>Room Name (optional): </label>
        <Field type="text" name="session[room]" className="form-control"/>
        <label>Date: </label>
        <DatePicker
          isClearable={true}
          onChange={(val) => this.setDate(val,formikProps)}
          value={values.session.date}
          name="session[date]"
          type="date"
          className="form-control"
        />
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <label>Start Time: </label>
            <br />
            <TimePicker
              name="session[start_time]"
              onChange={(val) => this.setTime(val,"session[start_time]", formikProps)}
              showSecond={false}
              use12Hours
              focusOnOpen={true}
              defaultValue={values.session.start_time ? moment(values.session.start_time).utc(0) : now}
              format={format}
              allowEmpty={true}
              autoComplete="off"
            />
          </div>

          <div className="col-xs-12 col-sm-6">
            <label>End Time (Optional): </label>
            <br />
            <TimePicker 
              name="session[end_time]"
              onChange={(val) => this.setTime(val,"session[end_time]", formikProps)}
              showSecond={false}
              focusOnOpen={true}
              defaultValue={values.session.end_time ? moment(values.session.end_time).utc(0) : now}
              format={format}
              allowEmpty={true}
              use12Hours
              autoComplete="off"
            />
          </div>
        </div>
        <label>Speakers (one per line, optional): </label>
        <Field name="session[speakers]" component="textarea" type="textarea" className="form-control" />
        <div className="text-right">
          <br />
          {this.renderDeleteButton()}
          <button type="submit" className="btn btn-primary"> Save </button>
          <button type="button" className="btn btn-danger" onClick={() => this.onCancel(formikProps)}> Cancel </button>
        </div>
      </Form>
    );
  }

  formUrl() {
    const { session } = this.props;
    if (session.id) {
      return `/admin/sessions/${session.id}`;
    }
    return `/admin/sessions/`;
  }

  formMethod() {
    const { session } = this.props;
    if (session.id) {
      return "PATCH";
    }
    return "POST";
  }

  successfullySaved(session) {
    const { updateSession, closeForm } = this.props;
    if (session && updateSession) {
      updateSession(session);
      this.forceUpdate();
    }
    if (closeForm) {
      closeForm();
    }
  }

  onCancel(formikProps) {
    const { onCancel } = this.props;
    formikProps.resetForm();
    if (onCancel) {
      onCancel();
    }
  }

  onDelete() {
    if (confirm("Are you sure you want to delete this? This action cannot be undone")) {
      const { session, onDelete } = this.props;
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(this.formUrl(), {
        method: "DELETE",
        redirect: "follow",
        headers: {
          "X-CSRF-Token": token
        }
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.error == null) {
            if (onDelete) {
              onDelete(session);
            }
          } else {
            alert(json.error);
          }
        });
    }
  }

  renderDeleteButton() {
    const { session } = this.props;
    if (session.id) {
      return (
        <button type="button" onClick={this.onDelete} className="btn-danger btn pull-left">
          Delete
        </button>
      );
    }
  }

  render() {
    const { session } = this.props;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          session: {
            id: session.id || null,
            title: session.title || "",
            date: session.date ? dateFormat(session.date, "yyyy-mm-dd",true) : null,
            start_time: session.start_time ? session.start_time : null,
            end_time: session.end_time ? session.end_time : null,
            room: session.room || "",
            speakers: session.speakers || "",
          }
        }}
        onSubmit={(values, { resetForm }) => {
          const form = document.getElementById(`${session.id || "new"}-session-form`);
          const data = new FormData(form);
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formUrl(), {
            method: this.formMethod(),
            redirect: "follow",
            body: data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then((response) => response.json())
            .then((json) => {
              if (json.error == null) {
                this.successfullySaved(json.session);
                resetForm();
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => this.renderForm(formikProps)}
      />
    );
  }
}

export default AdminSessionForm;
