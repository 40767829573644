import React from "react";
import moment from 'moment-timezone';
import createReactClass from 'create-react-class';

var Session = createReactClass({
  timeDisplay: function() {
    var startTime = moment.utc(this.props.session.start_time);

    // include end time
    if (this.props.session.end_time) {
      var stopTime = moment.utc(this.props.session.end_time);
      // 12hr time
      return (startTime.format('h:mm a') + " \u2013 " + stopTime.format('h:mm a')).replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.')
      // 24hr time
      // return startTime.format('HH:mm') + " \u2013 " + stopTime.format('HH:mm');
    }

    return (startTime.format('h:mm a')).replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.');
  },

  render: function() {
    const speakers = this.props.session.speakers.split("\n").map(speaker =>
      <div className="session-speaker" key={`speaker-${speaker}`}>
        {speaker}
      </div>
    );

    const room = this.props.session.room ? <div>{this.props.session.room}</div> : ""


    return (
      <div className={`session-box ${this.props.monitorRoom ? "session-box-room-monitor" : ""}`}>
        <div className="session-box-wrapper clearfix">
          <div className="session-time">
            {this.timeDisplay()}
          </div>
          <div className="content-col">
            <div className="session-title">
              <span className="session-name">{this.props.session.title}</span>
              {speakers}
            </div>
            <div className="session-room">
              {room}
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default Session
