import React from "react";

const Header = () => {
  return (
    <div className="header-container">
      <div className="header-lockup"></div>
    </div>
  )
}

export default Header
