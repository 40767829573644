import React from "react";
import Select from 'react-select';
import dateFormat from "dateformat";
import AdminSession from "./admin_session";
const sortByOptions = [
  { value: 'dates', label: 'Date/Time' },
  { value: 'rooms', label: 'Room' },
];

class AdminSessionSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: this.props.sessions,
      currentSessions: this.props.sessions,
      sortBy: sortByOptions[0],
      dateSelected: { value: "all", label: "All"},
    };

    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.updateSession = this.updateSession.bind(this);
    this.addSession = this.addSession.bind(this);
    this.deleteSession = this.deleteSession.bind(this);
  }

  getDates() {
    const { sessions } = this.state;
    return Array.from(
      new Set(sessions.map((session) => session.date))
    ).sort((a, b) => new Date(a) - new Date(b) );
  }

  getSessionsByDate(date) {
    const { sessions } = this.state;
    if (date === "all") {
      return sessions;
    } else{
      return sessions.filter((session) => session.date === date);
    }
  }

  updateSession(session) {
    this.setState((prevState) => {
      const index = prevState.sessions.findIndex((session2) => session2.id === session.id);
      const newState = prevState;
      newState.sessions[index] = session;
      return newState;
    });
  }

  deleteSession(session) {
    this.setState((prevState) => {
      const newState = prevState;
      newState.sessions = prevState.sessions.filter((session2) => !(session2.id === session.id));
      return newState;
    });
  }

  addSession(session) {
    this.setState((prevState) => {
      const index = prevState.sessions.findIndex((session2) => session2.id === session.id);
      const newState = prevState;
      newState.sessions.push( session );
      return newState;
    });
  }

  sortByTime(sessions) {
    return sessions.sort(function(a, b) {
        a = new Date(a.start_time);
        b = new Date(b.start_time);
        return a > b ? 1 : a<b ? -1 : 0;
    });
  }

  renderDates(date) {
    let dates;
    if (date.value === "all") {
      dates = this.getDates();
    } else {
      dates = [date.value];
    }
    return dates.map((date) => (
      <div key={`date-${date}`}>
        <h2 className="admin-session-schedule-date" id={encodeURI(date || "Unnassigned").replace('%20','_') }>
          {date ? dateFormat(new Date(date), "dddd, mmmm dS", true) : "Unnassigned"}
        </h2>
        { this.sortByTime(this.getSessionsByDate(date)).map((session) => (
          <AdminSession key={session.id} deleteSession={this.deleteSession} updateSession={this.updateSession} session={session} />
        ))}
      </div>
    ));
  }

  getRooms() {
    const { currentSessions } = this.state;
    return Array.from(
      new Set(currentSessions.map((session) => session.room))
    ).sort();
  }

  renderRooms() {
    const rooms = this.getRooms();
    const { dateSelected } = this.state;
    return rooms.map((room) => (
      <div key={`room-${room}`}>
        <h2 className="admin-session-schedule-date" id={encodeURI(room || "Unnassigned").replace('%20','_') }>
          {room || "Unnassigned"}
        </h2>
        { this.sortByTime(this.getSessionsByDate(dateSelected.value)).filter((session) => session.room === room).map((session) => (
          <AdminSession key={session.id} deleteSession={this.deleteSession} updateSession={this.updateSession} session={session} />
        ))}
      </div>
    ));
  }

  renderSessions() {
    const { sortBy, dateSelected } = this.state;
    switch (sortBy.value) {
      case "rooms":
        return this.renderRooms();  
      default:
        return this.renderDates(dateSelected);
    }
  }

  handleSortChange(selectedOption) {
    this.setState({ sortBy: selectedOption });
  }

  getDateOptions() {
    let dates = this.getDates().map(date => (
      { value: date || "Unnassigned", label: date || "Unnassigned" }
    ));
    dates.unshift({ value: 'all', label: 'All'});
    return dates; 
  }

  handleDateChange(selectedOption) {
    this.setState({ dateSelected: selectedOption, currentSessions: this.getSessionsByDate(selectedOption.value) });
  }

  render() {
    const { sortBy, dateSelected } = this.state;
    return (
      <div className="admin-session-schedule">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <label> Sort By </label>
            <Select
              value={sortBy}
              onChange={this.handleSortChange}
              options={sortByOptions}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <label style={{display:"block"}}> Date </label>
            <div className="date-select-container">
              <Select
                value={dateSelected}
                onChange={this.handleDateChange}
                options={this.getDateOptions()}
                style={"width : 100%"}
              />
            </div>
          </div>
        </div>
        {this.renderSessions()}
      </div>
    );
  }
}

export default AdminSessionSchedule;
