import React from "react";
import Session from './session';

const Sessions = ({ sessions, room }) => {
  var sessionCollect = [];
  if (sessions !== undefined) {
    sessionCollect = sessionCollect.concat(sessions);
  }
  if (sessionCollect.length === 0) {
    return <div />
  }
  else {
    return (
      <div>
        {sessionCollect.map((session, i) => {
          return <Session monitorRoom={room} key={session.id} session={session} />
        })}
      </div>
    );
  }
}

export default Sessions;
