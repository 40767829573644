import React, { useEffect, useState } from 'react'
import Agenda from './agenda';
const AssignableMonitor = (props) => {
  const registrationFetchTimeout = 30000;
  const registrationFetchTimeoutRandomDelayMax = 15000;
  const [registration, setRegistration] = useState(null);

  const fetchRegistration = () => {
    fetch(
      "/admin/registrations/" + localStorage.code,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then(response => {
      return response.json();
    }).then(json => {
      setRegistration(json.registration)
    })
  }

  useEffect(() => {
    getCode().then(x=> {
      fetchRegistration(x);
      setInterval(() => fetchRegistration(x), registrationFetchTimeout + Math.random() * registrationFetchTimeoutRandomDelayMax)
     });
  },[]);

  const renderMessage = (message) => {
    return (
      <div  className="assignable-monitor-message">
        <p>{message}</p>
      </div>
    )
  }

  const getParam  = (sname) => {
    let params = location.search.substr(location.search.indexOf("?") + 1);
    let sval = "";
    params = params.split("&");
    // split param and value into individual pieces
    for (let i = 0; i < params.length; i++) {
      const temp = params[i].split("=");
      if ([temp[0]] == sname) { sval = temp[1]; }
    }
    return sval;
  }

  const getCode = async () => {
    var overrideCode = getParam("code");
    var overrideTheme = getParam("theme");
    const code = localStorage.code
    // Use override code/theme instead of polling for one
    if (overrideCode !== "") {
      localStorage.setItem("code", overrideCode);
      localStorage.setItem("theme", overrideTheme);
    }

    // poll back end for code - normal operation mode

    else {
      if (code === undefined || code === null || code == "undefined") {
        await fetch(
          "/admin/registrations",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            }
          }
        ).then(response => {
          return response.json();
        }).then(json => {
          localStorage.setItem("code", json.code);
        })
      }
    }
    return localStorage.code;
  }


  const renderScreen = () => {
    if(registration == null) {
      return renderMessage("Loading...")
    }else if (!registration.room_name) {
      return renderMessage("Code: " + registration.code)
    }else if(registration && registration.room_name) {
      return <Agenda {...props} room={registration.room_name} />
    }else {
      return renderMessage("An issue occured...")
    }
  }
  
  return renderScreen()
}

export default AssignableMonitor