import React from "react";
import AdminSessionSchedule from "./admin_session_schedule";
import AdminSessionForm from "./admin_session_form"
class AdminSchedulePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFormOpen: false,
    };

    this.toggleNewFormOpen = this.toggleNewFormOpen.bind(this);
    this.beginCoreImport = this.beginCoreImport.bind(this);
    this.adminSessionSchedule = React.createRef();
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  toggleNewFormOpen() {
    this.setState({
      newFormOpen: !this.state.newFormOpen
    });
  }

  renderNewForm() {
    const { newFormOpen } = this.state;
    if (newFormOpen) {
      return (
        <AdminSessionForm updateSession={this.onFormSubmit} session={{}} />
      );
    }
  }

  getCoreImport() {
    fetch("/admin/core_import", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    }).then(response => {
      console.log(response);
      window.location.reload(true);
    });
  }

  beginCoreImport() {
    alert("Fetching sessions from core, please wait...");
    this.getCoreImport();
  }

  onFormSubmit(session) {
    this.setState({
      newFormOpen: false
    });
    console.log(this.adminSessionSchedule);
    this.adminSessionSchedule.current.addSession(session);
  }

  render() {
    console.log(this.adminSessionSchedule);
    return (
      <React.Fragment>
        <h1>Rolling Agenda Admin Panel</h1>
        <button onClick={this.toggleNewFormOpen} className="btn btn-primary">Add a New Session</button>
        <button onClick={this.beginCoreImport} className="btn btn-primary btn-spacer">Import Sessions from Core</button>
        {this.renderNewForm()}
        <h1>Event Schedule</h1>
        <AdminSessionSchedule ref={this.adminSessionSchedule} {...this.props} />
      </React.Fragment>
    );
  }
}

export default AdminSchedulePage;
