import React from "react";
import Session from './session';
import moment from 'moment-timezone';
const TimeGroupedSessions = ({ sessions }) => {


  const formatTime = (time) => {
      var startTime = moment.utc(time);
      return (startTime.format('h:mm a')).replace(/am/g, 'a.m.').replace(/pm/g, 'p.m.');
  }
  var sessionCollect = [];
  if (sessions !== undefined) {
    sessionCollect = sessionCollect.concat(sessions);
  }
  if (sessionCollect.length === 0) {
    return <div />
  }
  else {
    const sessionsGroupedByStartTime = []
    const startTimes = sessionCollect.map(session => session.start_time)
    startTimes.filter((x,i,t) => t.indexOf(x) === i).forEach(startTime => {
      const endTimes = sessionCollect.filter(x=>x.start_time == startTime).map(session => session.end_time)
      endTimes.forEach(endTime => {
        sessionsGroupedByStartTime.push({
          startTime: startTime,
          endTime: endTime,
          sessions: sessionCollect.filter(x=>x.start_time == startTime && x.end_time == endTime)
        })
      })
    })
      

    return  (
      <div>
        {sessionsGroupedByStartTime.map((timeSlot, i) => (
          <div className="session-time-group">
            <div className="session-time-group-time">
              {
                timeSlot.endTime ? (
                  `${formatTime(timeSlot.startTime)} \u2013 ${formatTime(timeSlot.endTime)}`
                ) :
                formatTime(timeSlot.startTime)
              }
            </div>
            {timeSlot.sessions.map(session => (
              <Session key={session.id} session={session} />
            ))}
          </div>
        ))}
      </div>
    )
  }
}

export default TimeGroupedSessions;
