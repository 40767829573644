// dependencies
import React from "react";
import moment from 'moment-timezone';
import createReactClass from 'create-react-class';
import FooterDateTime from './footer_date_time';
import Header from './header';
import Sessions from './sessions';
import TimeGroupedSessions from './time_grouped_sessions';
import Select from 'react-select';
// const DatePicker = require('react-datepicker');

const Agenda = createReactClass({
  getInitialState() {
    const {early_filter_time, fast_forward_date, session_length_filter, timezone} = this.props.settings;
    const dateNums = (fast_forward_date||"2020-10-10").split("-");
    const startDateYear = Number(dateNums[0]);
    const startDateMonth = Number(dateNums[1]);
    const startDateDay = Number(dateNums[2]);
    return {
      background: '#000000',
      sessionData: {},
      sessions: '',
      clock: this.clockTick(),
      hasPolled: false,
      overrideDate: this.getParam('date'),
      overrideTime: "",
      overrideRoom: this.getParam('room'),
      earlyFilterTime: early_filter_time,
      startDateYear: startDateYear,
      startDateMonth: startDateMonth - 1, // zero-indexed
      startDateDay: startDateDay,
      sessionLengthFilter: session_length_filter,
      timezone: timezone,
    };
  },

  setOverrideDate(e){
    this.setState({
      overrideDate: e.target.value
    }, this.clockUpdate)
  },

  setOverrideTime(e){
    this.setState({
      overrideTime: e.target.value
    }, this.clockUpdate)
  },

  setRoom(e){
    this.setState({
      overrideRoom: e.value
    }, this.clockUpdate)
  },

  getRoom() {
    if(this.state.overrideRoom){
      return this.state.overrideRoom
    }
    if(this.props.room) {
      return this.props.room
    }
    return null
  },


  renderTimemachine() {
    if(this.getParam('timemachine') !== ""){
      return (
        <div className="agenda-time-machine">
          <a href="/admin">
            Back to Admin Panel
          </a>
          <br/>
          <br/>
          <div className="time-machine-title">Time Machine:</div>
          <label>Date </label>
          <input value={this.state.overrideDate} onChange={this.setOverrideDate} type="date" className="form-control" />
          <br />
          <label>Time: </label>
          <input type="time" value={this.state.overrideTime} onChange={this.setOverrideTime} className="form-control" />
          <label>Room: </label>
          <Select
            value={{value: this.state.overrideRoom, label: (this.state.overrideRoom||"All")}}
            onChange={this.setRoom}
            options={this.getRoomOptions()}
          />
          
        </div>
      );
    }
  },

  getRoomOptions(){
    if(this.state.sessionData){
      return this.state.sessionData.map((session) => session.room).filter((x,i,t) => t.indexOf(x) === i).map((room) => ({value: room, label: room})).concat([
        {value: "", label: "All"}
      ]);
    }
  },

  startPoll() {
    const self = this;
    setTimeout(() => {
      self.pollData();
      if (!self.isMounted()) { return; }
      self._timer = setInterval(self.pollData, 60000);
    }, 1000);
  },

  pollData() {
    if (!this.isMounted()) {
      return;
    }

    const self = this;
    console.log('polling backend');
    $.ajax({
      url: self.props.dataUrl,
      type: 'GET',
      data: {},
      dataType: 'json',
      success(result, status, _xhr) {
        localStorage.setItem('sessions', JSON.stringify(result.sessions));
        if (self.isMounted()) {
          self.setState({
            sessionData: result.sessions,
            hasPolled: true
          });
        }
      },
      error(xhr, status, error) {
        console.log('Unable to load session data:');
        console.log(status);
        console.log(error);
        console.log(xhr);
      }
    });
  },

  clockUpdate() {
    console.log("Updating clock");
    this.setState({
      clock: this.clockTick()
    });
  },

  clockTick() {
    let override = ""
    let overrideTime = null
    let timezone = "America/Los_Angeles" //default value while loading
    let startDateYear = null;
    let startDateMonth = null;
    let startDateDay = null;
    if(this.state){
      override = this.state.overrideDate;
      overrideTime = this.state.overrideTime;
      timezone = this.state.timezone;
      startDateYear = this.state.startDateYear;
      startDateMonth = this.state.startDateMonth;
      startDateDay = this.state.startDateDay;
    }
    if (override && override !== "") {
      let hour = 8;
      let minute = 0;
      if(overrideTime){
        const timeElems = overrideTime.split(":");
        console.log({timeElems});
        hour =  parseInt(timeElems[0]);
        minute = parseInt(timeElems[1]);
      }
      const dateElems = override.split("-");
      const year = parseInt(dateElems[0]);
      const month = parseInt(dateElems[1]) - 1;
      const day = parseInt(dateElems[2]);
      const overrideDate = moment().tz(timezone).set({
        year, month, date: day, hour: hour, minute: minute
      });
      return overrideDate;
    }
    const now = moment().tz(timezone);
    // Remember, month is 0-indexed even in momentjs
    const firstDay = moment().tz(timezone).set({ year: startDateYear, month: startDateMonth, date: startDateDay });
    if (now.isBefore(firstDay, 'date')) {
      return firstDay;
    }
    return now;
  },

  startClock() {
    const self = this;
    setTimeout(() => {
      self.clockUpdate();
      if (!self.isMounted()) { return; }
      self._timer = setInterval(self.clockUpdate, 10000);
    }, 1000);
  },

  getParam(sname) {
    let params = location.search.substr(location.search.indexOf("?") + 1);
    let sval = "";
    params = params.split("&");
    // split param and value into individual pieces
    for (let i = 0; i < params.length; i++) {
      const temp = params[i].split("=");
      if ([temp[0]] == sname) { sval = temp[1]; }
    }
    return sval;
  },


  componentDidMount() {
    this.startPoll();
    this.startClock();
    this.state.background = localStorage.background;
  },

  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  },

  filteredSessions() {
    const self = this;
    const now = self.state.clock;
    const {earlyFilterTime, maxSessionLength, timezone} = self.state;
    const zone = moment.tz.zone(timezone);
    const offset = zone.parse(Date.UTC(now._d))
    const filtered = this.state.sessionData.filter( (session, i) => {
      const sessionStart = moment(session.date + " " + session.start_time.split('T')[1] + " " + offset, 'YYYY-M-D HH:mm Z');
      let sessionEnd = sessionStart;
      if(session.end_time) {
        sessionEnd = moment(session.date + " " + session.end_time.split('T')[1] + " " + offset, 'YYYY-M-D HH:mm Z').subtract(earlyFilterTime, "m");
      }

      console.log({sessionEnd, now});
      // first filter out sessions that aren't on this day
      if (!sessionStart.isSame(now, 'day')) {
        return false;
      }
      // next filter out sessions that have already concluded
      if (sessionEnd.isBefore(now, 'minute')) {
        return false;
      }

      // filter out anything whose length exceeds maxSessionLength
      if (parseInt(session.length) > maxSessionLength) {
        return false;
      }

      // anything that's left is good
      return true;
    });

    return filtered;
  },

  classNames() {
    return "agenda";
  },

  render() {
    if (this.state.hasPolled === false) {
      return (
        <div>
          <Header />
          <div className={this.classNames()}>
            <div className="loading">Loading</div>
          </div>
        </div>
      );
    } if (Object.keys(this.state.sessionData).length === 0) {
      return (
        <div>
          <Header />
          <div className={this.classNames()}>
            <h2 className="agenda-date">{this.state.clock.format('dddd, MMMM D')}</h2>
            <p>No agenda items for today.</p>
          </div>
        </div>
      );
    }
    const sessions = this.filteredSessions();

    if (sessions.length === 0) {
      return (
        <div>
          <Header />
          <div className="main-flexbox">
            {this.renderTimemachine()}
            <div className="agenda-header">
              <div className="agenda-sub-title">{this.state.clock.format('dddd, MMMM D')}</div>
            </div>
            <div className="no-remaining-sessions">No remaining sessions</div>
            <FooterDateTime clock={this.state.clock} />
          </div>
        </div>
      );
    }
    return (
      <div>
        <Header />
        <div className="main-flexbox">
          {this.renderTimemachine()}
          <div className="agenda-header">
            <div className="agenda-sub-title">
              {this.getRoom() ? this.getRoom() : this.state.clock.format('dddd, MMMM D')}
            </div>
          </div>
          <div className={this.classNames()}>
            <div id="sessions">
              <Sessions room={
                this.getRoom()
              } 
              sessions={sessions.filter(x=>{
                if(this.getRoom()) {
                  return x.room === this.getRoom()
                }else {
                  return true
                }
              })} />
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default Agenda;