import React, { Component } from 'react'
import moment from 'moment-timezone';
import Cookies from "universal-cookie";

export default class AdminSettingsPage extends Component {
  constructor(props) {
    super(props);
    const {early_filter_time, fast_forward_date, session_length_filter, timezone} = this.props.settings;
    this.state = {
      timezone: timezone,
      fastForwardDate: fast_forward_date,
      sessionLengthFilter: session_length_filter,
      earlyFilterTime: early_filter_time,
    }
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleFastForwardDateChange = this.handleFastForwardDateChange.bind(this);
    this.handleSessionLengthFilterChange = this.handleSessionLengthFilterChange.bind(this);
    this.handleEarlyFilterTimeChange = this.handleEarlyFilterTimeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTimezoneChange(e) {
    this.setState({timezone: e.target.value})
  }

  handleFastForwardDateChange(e) {
    this.setState({fastForwardDate: e.target.value})
  }

  handleSessionLengthFilterChange(e) {
    this.setState({sessionLengthFilter: e.target.value.length > 1 ? Number(e.target.value) : null})
  }

  handleEarlyFilterTimeChange(e) {
    this.setState({earlyFilterTime: e.target.value.length > 1 ? Number(e.target.value) : null})
  }

  handleSubmit(e) {
    e.preventDefault()
    const {timezone, fastForwardDate, sessionLengthFilter, earlyFilterTime} = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token")
    fetch('/admin/settings/update', {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
       "timezone": timezone,
        "fast_forward_date": fastForwardDate,
        "session_length_filter": sessionLengthFilter,
        "early_filter_time": earlyFilterTime,
      })
    })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      if (json.success === true) {
        alert("Settings successfully updated")
      } else {
        alert("Something went wrong with your request")
      }
    })
    .catch((err) => {
      console.log(err);
    })
  }

  render() {
    const {earlyFilterTime, fastForwardDate, sessionLengthFilter, timezone} = this.state;
    const timezones = moment.tz.names();
    return (
      <>
        <h1>Settings</h1>
        <form className="settings-form" onSubmit={this.handleSubmit}>
          <label>Timezone</label>
          <select selected={timezone} onChange={this.handleTimezoneChange}>
            {
              timezones.map((zone) => {
                if (zone == timezone) {
                  return (<option selected>{zone}</option>)
                } else {
                  return (<option>{zone}</option>)
                }
              })
            }
          </select>
          <label>Fast Forward Date</label>
          <input type="date" value={fastForwardDate} onChange={this.handleFastForwardDateChange}/>
          <label>Session Length Filter (minutes)</label>
          <input type="number" value={sessionLengthFilter} onChange={this.handleSessionLengthFilterChange}/>
          <label>Early Filter Time (minutes)</label>
          <input type="number" value={earlyFilterTime} onChange={this.handleEarlyFilterTimeChange}/>
          <button type="submit">Save</button>
        </form>
      </>
    )
  }
}
